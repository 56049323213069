<template>
  <span class="info-popover">
    <span class="info-popover-icon"><i class="fa fa-square-info fa-xs"></i></span>
    <div class="info-popover-content">
      <slot>{{ text }}</slot>
    </div>
  </span>
</template>
<script>
export default {
  name: 'InfoPopover',
  props: {
    text: {
      type: String,
      default: 'Title'
    }
  }
};
</script>
<style lang="scss">
$width: 250px;

.info-popover {
  position: relative;
  line-height: 18px;
}

.info-popover-icon {
  cursor: pointer;
}

.info-popover-content {
  opacity: 0;
  position: absolute;
  box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.14);
  top: -60px;
  left: calc(50% - #{$width / 2});
  width: $width;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 10px 15px;
  background: #fff;
  font-size: 12px;
  font-weight: 600;
  transition: opacity 0.2s ease-out;
}

.info-popover-icon:hover + .info-popover-content {
  opacity: 1;
}
</style>
