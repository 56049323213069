var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "info-popover"
  }, [_vm._m(0), _c('div', {
    staticClass: "info-popover-content"
  }, [_vm._t("default", [_vm._v(_vm._s(_vm.text))])], 2)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "info-popover-icon"
  }, [_c('i', {
    staticClass: "fa fa-square-info fa-xs"
  })]);
}]

export { render, staticRenderFns }