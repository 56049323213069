<template>
  <div class="d-flex align-items-center">
    <div v-if="icon" class="pr-4">
      <i class="fa fa-2x" :class="icon"></i>
    </div>
    <div>
      <p class="font-w600 mb-1">{{ title }}</p>
      <p class="font-size-h2 mb-0">
        <slot>{{ value }}</slot>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SummaryBox',
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    value: {
      type: String,
      default: 'Title'
    },
    icon: {
      type: String,
      default: ''
    }
  }
};
</script>
