var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-timeline-page"
    }
  }, [_c('div', {
    staticClass: "d-flex p-2"
  }, [_vm._l(_vm.yearFilters, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "p-2"
    }, [_c('button', {
      staticClass: "btn",
      class: {
        'btn-primary': _vm.filter === item
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickYear(item);
        }
      }
    }, [_vm._v(_vm._s(item))])]);
  }), _c('div', {
    staticClass: "ml-auto p-2"
  }, [_vm.$permissions.write('asset', _vm.asset) ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "data-cy": "add-entry-btn"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickAddEntry($event);
      }
    }
  }, [_vm._v(" Add Entry ")]) : _vm._e()])], 2), _vm.loading ? _c('div', {
    staticClass: "d-flex justify-content-center mb-5"
  }, [_vm._m(0)]) : _vm._e(), _c('ul', {
    staticClass: "timeline timeline-alt"
  }, [_vm._l(_vm.filteredTimeline, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "timeline-event"
    }, [_c('div', {
      staticClass: "timeline-event-icon bg-dark"
    }, [_c('i', {
      staticClass: "fa",
      class: "fa-".concat(item.icon || 'fa-check')
    })]), _c('div', {
      staticClass: "timeline-event-block block block-rounded",
      attrs: {
        "data-toggle": "appear"
      }
    }, [_c('div', {
      staticClass: "block-header block-header-default"
    }, [_c('h3', {
      staticClass: "block-title"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "block-options"
    }, [item.timelineId == 'manual' ? _c('span', [_vm.$permissions.write('asset', _vm.asset) ? _c('button', {
      staticClass: "btn-block-option text-secondary",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickEdit(item);
          }($event);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-pencil"
    })]) : _vm._e(), _vm.$permissions.write('asset', _vm.asset) ? _c('button', {
      staticClass: "btn-block-option text-danger",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickDelete(item);
          }($event);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-trash"
    })]) : _vm._e()]) : _vm._e(), _c('div', {
      staticClass: "timeline-event-time block-options-item font-size-sm font-w600"
    }, [_vm._v(_vm._s(_vm._f("date")(item.date)))])])]), _c('div', {
      staticClass: "block-content"
    }, [_c('div', {
      staticClass: "alert d-flex align-items-center justify-content-between",
      class: "alert-".concat(item.status || 'success'),
      attrs: {
        "role": "alert"
      }
    }, [_c('div', {
      staticClass: "flex-fill mr-3"
    }, [_c('p', {
      staticClass: "mb-0",
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })]), _c('div', {
      staticClass: "flex-00-auto"
    }, [new Date(item.date) < new Date() ? _c('i', {
      staticClass: "fa fa-fw fa-check-circle"
    }) : _c('i', {
      staticClass: "fa fa-fw fa-dot-circle"
    })])]), item.accountIds ? _c('p', {
      staticClass: "font-w600"
    }, _vm._l(_vm.filteredAccounts(item.accountIds), function (acc) {
      return _c('span', {
        key: acc.id,
        staticClass: "badge text-capitalize badge-primary m-1"
      }, [_vm._v(_vm._s(acc.name))]);
    }), 0) : _vm._e()])])]);
  }), !_vm.showMore && _vm.filteredTimeline.length === 10 ? _c('li', [_c('div', {
    staticClass: "timeline-event-block block block-rounded"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.showMore = true;
      }
    }
  }, [_vm._v("Show More")])])])]) : _vm._e()], 2), _c('ConfirmModal', {
    attrs: {
      "open": _vm.mode === 'edit',
      "title": "Edit Timeline Entry",
      "lg-size": ""
    },
    on: {
      "submit": _vm.onClickSubmit,
      "close": _vm.clearForm
    }
  }, [_vm.mode === 'edit' ? _c('div', {
    staticClass: "block-content bg-body-light"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('FormGroup', {
    attrs: {
      "id": "date",
      "label": "Date",
      "type": "datePicker"
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  }), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Title")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.title,
      expression: "form.title"
    }],
    staticClass: "form-control",
    domProps: {
      "value": _vm.form.title
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "title", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Status")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.status,
      expression: "form.status"
    }],
    staticClass: "form-control",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.form, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "success"
    }
  }, [_vm._v("Success")]), _c('option', {
    attrs: {
      "value": "danger"
    }
  }, [_vm._v("Danger")]), _c('option', {
    attrs: {
      "value": "warning"
    }
  }, [_vm._v("Warning")]), _c('option', {
    attrs: {
      "value": "info"
    }
  }, [_vm._v("Info")])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Content")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.content,
      expression: "form.content"
    }],
    staticClass: "form-control",
    domProps: {
      "value": _vm.form.content
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "content", $event.target.value);
      }
    }
  })]), _c('FormGroup', {
    staticClass: "mb-5",
    attrs: {
      "id": "accountIds",
      "label": "Associated Accounts",
      "description": "Optionally associate accounts with this event",
      "description-position": "top",
      "placeholder": "Add an account...",
      "type": "select-array-list",
      "options": _vm.accountOptions
    },
    model: {
      value: _vm.form.accountIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "accountIds", $$v);
      },
      expression: "form.accountIds"
    }
  })], 1)])]) : _vm._e()]), _c('ConfirmModal', {
    attrs: {
      "open": _vm.mode === 'delete',
      "title": "Delete Event",
      "text": "Please confirm you would like to delete event"
    },
    on: {
      "close": function close($event) {
        _vm.mode = null;
      },
      "submit": _vm.onClickConfirmDelete
    }
  }), _c('ConfirmModal', {
    attrs: {
      "open": _vm.mode === 'create',
      "title": "Submit An Entry",
      "text": "Please confirm you would like to delete event",
      "lg-size": ""
    },
    on: {
      "close": function close($event) {
        _vm.mode = null;
      },
      "submit": _vm.onClickSubmit
    }
  }, [_vm.mode === 'create' ? _c('div', {
    staticClass: "block",
    attrs: {
      "data-cy": "add-entry"
    }
  }, [_c('div', {
    staticClass: "block-content bg-body-light"
  }, [_c('p', [_vm._v("Use the form below to submit a new event.")]), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('FormGroup', {
    attrs: {
      "id": "date",
      "label": "Date",
      "type": "datePicker"
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  }), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Title")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.title,
      expression: "form.title"
    }],
    staticClass: "form-control",
    domProps: {
      "value": _vm.form.title
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "title", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Status")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.status,
      expression: "form.status"
    }],
    staticClass: "form-control",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.form, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "success"
    }
  }, [_vm._v("Success")]), _c('option', {
    attrs: {
      "value": "danger"
    }
  }, [_vm._v("Danger")]), _c('option', {
    attrs: {
      "value": "warning"
    }
  }, [_vm._v("Warning")]), _c('option', {
    attrs: {
      "value": "info"
    }
  }, [_vm._v("Info")])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Content")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.content,
      expression: "form.content"
    }],
    staticClass: "form-control",
    domProps: {
      "value": _vm.form.content
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "content", $event.target.value);
      }
    }
  })]), _c('FormGroup', {
    staticClass: "mb-5",
    attrs: {
      "id": "accountIds",
      "label": "Associated Accounts",
      "description": "Optionally associate accounts with this event",
      "description-position": "top",
      "placeholder": "Add an account...",
      "type": "select-array-list",
      "options": _vm.accountOptions
    },
    model: {
      value: _vm.form.accountIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "accountIds", $$v);
      },
      expression: "form.accountIds"
    }
  })], 1)])])]) : _vm._e()])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary mb-4",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading timeline...")])])]);
}]

export { render, staticRenderFns }