<template>
  <div data-cy="asset-timeline-page">
    <div class="d-flex p-2">
      <div v-for="(item, index) in yearFilters" :key="index" class="p-2">
        <button class="btn" :class="{ 'btn-primary': filter === item }" @click.prevent="onClickYear(item)">{{ item }}</button>
      </div>
      <div class="ml-auto p-2">
        <button v-if="$permissions.write('asset', asset)" class="btn btn-primary" data-cy="add-entry-btn" @click.prevent="onClickAddEntry">
          Add Entry
        </button>
      </div>
    </div>
    <div v-if="loading" class="d-flex justify-content-center mb-5">
      <div class="text-center">
        <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
          <span class="sr-only">Loading timeline...</span>
        </div>
      </div>
    </div>

    <ul class="timeline timeline-alt">
      <li v-for="(item, index) in filteredTimeline" :key="index" class="timeline-event">
        <div class="timeline-event-icon bg-dark">
          <i class="fa" :class="`fa-${item.icon || 'fa-check'}`"></i>
        </div>
        <div class="timeline-event-block block block-rounded" data-toggle="appear">
          <div class="block-header block-header-default">
            <h3 class="block-title">{{ item.title }}</h3>
            <div class="block-options">
              <span v-if="item.timelineId == 'manual'">
                <button v-if="$permissions.write('asset', asset)" class="btn-block-option text-secondary" @click.prevent="() => onClickEdit(item)">
                  <i class="fa fa-fw fa-pencil"></i>
                </button>
                <button v-if="$permissions.write('asset', asset)" class="btn-block-option text-danger" @click.prevent="() => onClickDelete(item)">
                  <i class="fa fa-fw fa-trash"></i>
                </button>
              </span>
              <div class="timeline-event-time block-options-item font-size-sm font-w600">{{ item.date | date }}</div>
            </div>
          </div>
          <div class="block-content">
            <div class="alert d-flex align-items-center justify-content-between" :class="`alert-${item.status || 'success'}`" role="alert">
              <div class="flex-fill mr-3">
                <p class="mb-0" v-html="item.content"></p>
              </div>
              <div class="flex-00-auto">
                <i v-if="new Date(item.date) < new Date()" class="fa fa-fw fa-check-circle"></i>
                <i v-else class="fa fa-fw fa-dot-circle"></i>
              </div>
            </div>

            <p v-if="item.accountIds" class="font-w600">
              <span v-for="acc in filteredAccounts(item.accountIds)" :key="acc.id" class="badge text-capitalize badge-primary m-1">{{
                acc.name
              }}</span>
            </p>
          </div>
        </div>
      </li>
      <li v-if="!showMore && filteredTimeline.length === 10">
        <div class="timeline-event-block block block-rounded">
          <div class="col-12">
            <button class="btn btn-primary btn-block" @click.prevent="showMore = true">Show More</button>
          </div>
        </div>
      </li>
    </ul>

    <ConfirmModal :open="mode === 'edit'" title="Edit Timeline Entry" lg-size @submit="onClickSubmit" @close="clearForm">
      <div v-if="mode === 'edit'" class="block-content bg-body-light">
        <div class="d-flex justify-content-between align-items-center">
          <div class="col-lg-12">
            <FormGroup id="date" v-model="form.date" label="Date" type="datePicker" />
            <div class="form-group">
              <label>Title</label>
              <input v-model="form.title" class="form-control" />
            </div>
            <div class="form-group">
              <label>Status</label>
              <select v-model="form.status" class="form-control">
                <option value="success">Success</option>
                <option value="danger">Danger</option>
                <option value="warning">Warning</option>
                <option value="info">Info</option>
              </select>
            </div>

            <div class="form-group">
              <label>Content</label>
              <input v-model="form.content" class="form-control" />
            </div>
            <FormGroup
              id="accountIds"
              v-model="form.accountIds"
              class="mb-5"
              label="Associated Accounts"
              description="Optionally associate accounts with this event"
              description-position="top"
              placeholder="Add an account..."
              type="select-array-list"
              :options="accountOptions"
            />
          </div>
        </div>
      </div>
    </ConfirmModal>
    <ConfirmModal
      :open="mode === 'delete'"
      title="Delete Event"
      :text="`Please confirm you would like to delete event`"
      @close="mode = null"
      @submit="onClickConfirmDelete"
    />
    <ConfirmModal
      :open="mode === 'create'"
      title="Submit An Entry"
      :text="`Please confirm you would like to delete event`"
      lg-size
      @close="mode = null"
      @submit="onClickSubmit"
    >
      <div v-if="mode === 'create'" class="block" data-cy="add-entry">
        <div class="block-content bg-body-light">
          <p>Use the form below to submit a new event.</p>
          <div class="row align-items-center">
            <div class="col">
              <FormGroup id="date" v-model="form.date" label="Date" type="datePicker" />
              <div class="form-group">
                <label>Title</label>
                <input v-model="form.title" class="form-control" />
              </div>
              <div class="form-group">
                <label>Status</label>
                <select v-model="form.status" class="form-control">
                  <option value="success">Success</option>
                  <option value="danger">Danger</option>
                  <option value="warning">Warning</option>
                  <option value="info">Info</option>
                </select>
              </div>

              <div class="form-group">
                <label>Content</label>
                <input v-model="form.content" class="form-control" />
              </div>
              <FormGroup
                id="accountIds"
                v-model="form.accountIds"
                class="mb-5"
                label="Associated Accounts"
                description="Optionally associate accounts with this event"
                description-position="top"
                placeholder="Add an account..."
                type="select-array-list"
                :options="accountOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </ConfirmModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import moment from 'moment';

import FormGroup from '@/components/FormGroup';
import ConfirmModal from '@/components/ConfirmModal';

export default {
  name: 'AssetTimeline',
  components: {
    FormGroup,
    ConfirmModal
  },
  data() {
    return {
      timeline: [],
      showAdd: false,
      mode: null,
      showMore: false,
      eventId: '',
      form: {
        date: moment.utc().startOf('day').toDate(),
        title: '',
        status: '',
        content: '',
        icon: 'calendar',
        accountIds: []
      },
      filter: new Date().getFullYear().toString()
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      entity: 'entity/entity',
      loadingAction: 'account/loadingAction',
      loading: 'account/loading',
      accounts: 'asset/accounts'
    }),
    accountOptions() {
      return this.accounts.map(acc => ({
        label: `${acc.type} - ${acc.name} [${acc.meterPointNumber}]`,
        value: acc._id
      }));
    },
    filteredTimeline() {
      const timeline = this.timeline.filter(t => !this.filter || this.filter === 'All' || moment(t.date).format('YYYY') === this.filter);
      if (!this.showMore && timeline.length > 10) timeline.length = 10;
      return timeline;
    },
    yearFilters() {
      return this.timeline
        .reduce(
          (acc, currentValue) => {
            const yearInList = acc.some(y => y === moment(currentValue.date).format('YYYY'));
            if (!yearInList) return [...acc, moment(currentValue.date).format('YYYY')];
            return acc;
          },
          ['All']
        )
        .sort();
    }
  },
  async mounted() {
    this.getAccounts({ id: this.$route.params.id });
    this.updatePage();
  },
  methods: {
    ...mapActions({
      addEvent: 'asset/createTimelineEvent',
      updateEvent: 'asset/updateTimelineEvent',
      deleteEvent: 'asset/deleteTimelineEvent',
      fetchTimelineData: 'asset/getTimelineData',
      getAccounts: 'asset/accounts'
    }),
    onClickYear(item) {
      this.filter = item;
      this.showMore = false;
    },
    filteredAccounts(accountIds) {
      return this.accounts
        .filter(acc => accountIds.some(a => acc._id === a))
        .map(acc => ({
          name: `${acc.name}`,
          id: acc._id
        }));
    },
    async onClickSubmit() {
      let entry = null;

      let dataToSend = {
        data: {
          ...this.form
        },
        _id: this.$route.params.id
      };

      let success = '';
      let error = '';

      if (this.mode === 'create') {
        entry = await this.addEvent(dataToSend);
        success = 'Submitted Entry.';
        error = 'Could not submit entry';
      } else {
        dataToSend.eventId = this.form.id;
        entry = await this.updateEvent(dataToSend);
        success = 'Edit Complete.';
        error = 'Could not edit event';
      }

      if (entry) {
        this.$toasted.success(success, { position: 'bottom-center', duration: 3000 });
        this.updatePage();
      } else {
        this.$toasted.error(error, { position: 'bottom-center', duration: 3000 });
      }
      this.clearForm();
    },
    async onClickConfirmDelete() {
      const deleteEvent = await this.deleteEvent({ _id: this.$route.params.id, eventId: this.eventId });
      if (deleteEvent) {
        this.$toasted.success('Event Deleted.', { position: 'bottom-center', duration: 3000 });
        this.updatePage();
      } else {
        this.$toasted.error('Could not delete event.', { position: 'bottom-center', duration: 3000 });
      }
      this.clearForm();
    },
    onClickDelete(event) {
      this.mode = 'delete';
      this.eventId = event.id;
    },
    async updatePage() {
      this.timeline = await this.fetchTimelineData({ id: this.$route.params.id });

      const yearToday = new Date().getFullYear().toString();
      const yearMatch = this.yearFilters.some(y => y === yearToday);

      if (yearMatch) {
        this.filter = yearToday;
      } else {
        this.filter = 'All';
      }
    },
    onClickEdit(entry) {
      this.form = {
        date: entry.date,
        title: entry.title,
        status: entry.status,
        content: entry.content,
        icon: entry.icon,
        id: entry.id,
        accountIds: entry.accountIds
      };
      this.mode = 'edit';
    },
    onClickAddEntry() {
      this.showAdd = true;
      this.mode = 'create';
    },
    clearForm() {
      this.form = {
        date: moment.utc().startOf().toDate(),
        title: '',
        status: '',
        content: '',
        icon: 'calendar',
        accountIds: []
      };
      this.mode = null;
    }
  }
};
</script>
