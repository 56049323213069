var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.icon ? _c('div', {
    staticClass: "pr-4"
  }, [_c('i', {
    staticClass: "fa fa-2x",
    class: _vm.icon
  })]) : _vm._e(), _c('div', [_c('p', {
    staticClass: "font-w600 mb-1"
  }, [_vm._v(_vm._s(_vm.title))]), _c('p', {
    staticClass: "font-size-h2 mb-0"
  }, [_vm._t("default", [_vm._v(_vm._s(_vm.value))])], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }